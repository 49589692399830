import React from "react";
import "./Home.css";
import Arrow1 from "../../assets/images/Arrow1.png";
import Arrow2 from "../../assets/images/Arrow2.png";
import about1 from "../../assets/images/about1.png";
import about2 from "../../assets/images/about2.png";
import about3 from "../../assets/images/about3.png";
import homeabout from "../../assets/images/homeabout.png";
import glreach from "../../assets/images/glreach.png";
import cultural from "../../assets/images/cultural.png";
import customer from "../../assets/images/customer.png";
import comprehen from "../../assets/images/comprehen.png";
import trusted from "../../assets/images/trusted.png";
import { Link } from "react-router-dom";
import Contactcom from "../../Component/Contact/Contactcom";

const Home = () => {
  return (
    <main>
      {/*Hero Section*/}
      <div className="hero-section">
        <div className="container text-left mt-0">
          <h1>
            BRIDGING <span className="highlight">BORDERS</span>, CULTURES,
            <br />
            AND <span className="highlight">OPPORTUNITIES</span>
          </h1>
          <p className="subtitle mt-4">
            Connecting people, services, and cultures globally through
            <br /> importation, education, tourism, and more.
          </p>
          <Link to="/service" className="btn  learn-more-btn">
            Learn More
          </Link>
        </div>
      </div>

      {/*About Us */}
      <div className="aboutUsSection container mt-5 mb-4">
        <div className="row">
          <div className="col-md-6 aboutUsText">
            <div class="about-highlight-container mb-5">
              <div class="about-highlight-background"></div>
              <h2 class="about-highlight-text mb-2">About Us</h2>
            </div>
            <p>
              Hands Across Borders is a limited liability company dedicated to
              fostering cultural exchange and offering essential services like
              food importation, tourism, and education. Our mission is to bring
              the world closer together by connecting Japan and Africa through
              people-focused services and global trade.
              <br /> <strong>What We Aim to Achieve:</strong>
            </p>
            <ul className="mt-5">
              <li>
                <img src={about1} alt="Promote Global Connectivity" />
                <div>
                  <span className="topic">Promote Global Connectivity:</span>
                  <p className="subtitle">
                    We aim to create a world where individuals and businesses
                    can collaborate seamlessly across borders.
                  </p>
                </div>
              </li>
              <li>
                <img src={about2} alt="Foster Cultural Exchange" />
                <div>
                  <span className="topic">Foster Cultural Exchange:</span>
                  <p className="subtitle">
                    Through our arts, music, and tourism services, we strive to
                    bring African and Japanese cultures closer together.
                  </p>
                </div>
              </li>
              <li>
                <img src={about3} alt="Support Education" />
                <div>
                  <span className="topic">Support Education:</span>
                  <p className="subtitle">
                    We help students from around the world access Japanese
                    educational opportunities, making international learning
                    more accessible.
                  </p>
                </div>
              </li>
            </ul>
            <Link to="/about">
            <button className="readMoreButton">Read More</button>
            </Link>
            
          </div>
          <div className="col-md-6 aboutUsImage">
            <img src={homeabout} alt="Cargo Ship" />
          </div>
        </div>
      </div>
      {/*Our services */}
      <div className="container text-center our-services my-5 ">
        <div className="text-center">
          <div class="highlight-container mb-3 mt-5">
            <div class="highlight-background"></div>
            <h2 class="highlight-text mb-3">Our Services</h2>
          </div>
        </div>
        <p className="text-center subtext">
          At <strong>Hands Across Borders</strong>, we pride ourselves on
          offering comprehensive and people-focused solutions across multiple
          industries. Each service is tailored to meet the unique needs of our
          clients, ensuring that every interaction is rooted in trust and
          excellence.
        </p>

        <div className="row mt-5">
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card service-card h-100">
              <div className="card-body service-1 d-flex flex-column">
                <h3 className="card-title">
                  Human resource <br /> services
                </h3>
                <p className="card-text">
                  We help individuals and businesses find the right talent or
                  job opportunities across borders, offering expert HR services
                  and recruitment support.
                </p>
                <Link to="/service#Human-Resource-Services" className="learn-more mt-auto">
                  Learn More <img src={Arrow1} alt="arrow" />
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card service-card h-100">
              <div className="card-body service-2 d-flex flex-column">
                <h3 className="card-title">
                  Used Cars, Parts &<br /> Items
                </h3>
                <p className="card-text">
                  We trade in used cars, parts, and other goods, ensuring
                  quality and affordability for customers globally.
                </p>
                <Link to="/service#Used-Cars,-Parts-&-Items" className="learn-more mt-auto">
                  Learn More <img src={Arrow2} alt="arrow" />
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card service-card h-100">
              <div className="card-body service-1 d-flex flex-column">
                <h3 className="card-title">Tourism</h3>
                <p className="card-text">
                  We provide unique cultural tours between Japan and Africa,
                  offering immersive travel experiences and homestays.
                </p>
                <Link to="/service#Tourism" className="learn-more mt-auto">
                  Learn More <img src={Arrow1} alt="arrow" />
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card service-card h-100">
              <div className="card-body service-2 d-flex flex-column">
                <h3 className="card-title">Education</h3>
                <p className="card-text">
                  We help students connect with Japanese universities and
                  language schools, offering support with admissions,
                  documentation, and accommodations.
                </p>
                <Link to="/service#Education" className="learn-more mt-auto">
                  Learn More <img src={Arrow2} alt="arrow" />
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card service-card h-100">
              <div className="card-body service-1 d-flex flex-column">
                <h3 className="card-title">Food & Culture</h3>
                <p className="card-text">
                  We import African food into Japan, offering the taste of
                  Africa and sharing cultural traditions through cuisine.
                </p>
                <Link to="/service#Food-Importation" className="learn-more mt-auto">
                  Learn More <img src={Arrow1} alt="arrow" />
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card service-card h-100">
              <div className="card-body service-2 d-flex flex-column">
                <h3 className="card-title">Arts & Crafts</h3>
                <p className="card-text">
                  We promote and showcase African and Japanese arts, supporting
                  cultural exchange through creative craftsmanship.
                </p>
                <Link to="/service#Arts-&-Crafts" className="learn-more mt-auto">
                  Learn More <img src={Arrow2} alt="arrow" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*WHY CHOOSE US */}
      <div className="why-choose-us-container">
        <div className="text-center">
          <div class="why-highlight-container">
            <div class="why-highlight-background"></div>
            <h2 class="why-highlight-text">Why Choose Us</h2>
          </div>
        </div>

        <p className="text-center subtext mt-3">
          At Hands Across Borders, we stand out because of our commitment to
          excellence and global connectivity. Here’s why we are the right choice
          for you
        </p>

        <div className="container mt-5">
          <div className="row">
            <div className="col-md-4 mb-4">
              <div className="card card-hover">
                <div className="card-body text-center">
                  <img src={glreach} alt="Global Reach" className="card-icon" />
                  <h5 className="card-title-1">Global Reach</h5>
                  <p className="card-text">
                    We connect businesses and individuals across borders,
                    ensuring seamless collaboration and trade between Africa and
                    Japan.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-4">
              <div className="card card-hover">
                <div className="card-body text-center">
                  <img
                    src={cultural}
                    alt="Cultural Expertise"
                    className="card-icon"
                  />
                  <h5 className="card-title">Cultural Expertise</h5>
                  <p className="card-text">
                    With a deep understanding of both African and Japanese
                    cultures, we specialize in bringing authentic experiences
                    and services to our clients.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-4">
              <div className="card card-hover">
                <div class="services__border"></div>
                <div className="card-body text-center">
                  <img
                    src={comprehen}
                    alt="Comprehensive Services"
                    className="card-icon"
                  />
                  <h5 className="card-title-1">
                    Comprehensive <br />
                    Services
                  </h5>
                  <p className="card-text">
                    From food importation and used goods to tourism and
                    education, we offer a wide range of services designed to
                    meet your unique needs.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-4 offset-md-2">
              <div className="card card-hover">
                <div className="card-body text-center">
                  <img
                    src={customer}
                    alt="Customer-Centric Approach"
                    className="card-icon"
                  />
                  <h5 className="card-title">
                    Customer-Centric <br />
                    Approach
                  </h5>
                  <p className="card-text">
                    We prioritize customer satisfaction in every aspect of our
                    business, ensuring personalized service and long-term
                    relationships.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-4">
              <div className="card card-hover">
                <div className="card-body text-center">
                  <img
                    src={trusted}
                    alt="Trusted Experience"
                    className="card-icon"
                  />
                  <h5 className="card-title-1">Trusted Experience</h5>
                  <p className="card-text">
                    Our team brings extensive knowledge and experience across
                    multiple industries, making us a reliable partner in global
                    trade and cultural exchange.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*CONTACT US */}
      <Contactcom/>
    </main>
  );
};

export default Home;
