import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './Contact.css';
import Contactcom from '../../Component/Contact/Contactcom';
import { motion } from 'framer-motion';

// Leaflet icon settings
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

export const Contact = () => {
  const position = [36.3103, 139.8069];

  return (
    <main>
      {/* Contact Us Section with animation */}
      <motion.div
        className="about-us-section"
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <div className="container text-center py-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10">
              <h2 className="mb-4">
                CONTACT <span>US</span>
              </h2>
              <p className="lead">
                We’re here to help with any inquiries or requests.
              </p>
            </div>
          </div>
        </div>
      </motion.div>

      {/* Map Section with animation */}
      <motion.div
        className="container-fluid  w-100 mt-5"
        style={{
          width: '100%',  // Full width
          padding: '0',   // Ensure no padding reduces the width
          margin: '0',    // Reset any margins
        }}
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 1, delay: 0.3 }}
      >
        <div className="map-container mt-3">
          <MapContainer
            center={position}
            zoom={10}
            scrollWheelZoom={false}
            style={{ height: '500px', width: '100%' }}
          >
            <TileLayer
              url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
            />
            <Marker position={position}>
              <Popup>
                329-0201 Awanomiya 1-4-25 Maison Soleil 201 Tochigi Prefecture
                Oyama city
                Japan.
              </Popup>
            </Marker>
          </MapContainer>
        </div>
      </motion.div>

      {/* Contact Form Section with animation */}
      <motion.div
        className="contact-contact"
        initial={{ x: -200, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <Contactcom />
      </motion.div>
    </main>
  );
}
