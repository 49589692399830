import React from 'react'
import './About.css'
import check from '../../assets/images/check.png'
import our from '../../assets/images/our-story.png'
import Contactcom from '../../Component/Contact/Contactcom'
import team1 from '../../assets/images/team1.png'
import team2 from '../../assets/images/team2.png'
import team3 from '../../assets/images/team3.png'
import team4 from '../../assets/images/team4.png'



export const About = () => {
  return (
  <main>
   <div className="about-us-section">
      <div className="container text-center py-5">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <h2 className="mb-4">
              ABOUT <span>US</span>
            </h2>
            <p className="lead">
              Whether it's bringing the taste of Africa to Japan, facilitating cross-border educational opportunities, or enabling meaningful cultural exchanges through tourism, we are dedicated to making the world feel smaller and more connected.
            </p>
          </div>
        </div>
      </div>
    </div>

    <section className="introduction-section">
      <div className="container ">
      <div className="text-center">
          <div class="intro-highlight-container mb-3 mt-5">
            <div class="intro-highlight-background"></div>
            <h2 class="intro-highlight-text mb-3">Introduction</h2>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-12 col-md-10 text-center">
         
            
            <p className="lead">
              Hands Across Borders is a dynamic limited liability company with a global outlook, committed to bridging the gap between Japan and Africa. We specialize in fostering cultural exchange and providing essential services that include food importation, tourism, education, and more. Our aim is to create lasting connections that transcend borders, offering individuals and businesses the opportunity to experience the richness of different cultures.
            </p>
          </div>
        </div>
        <div className="row justify-content-center mt-4">
          <div className="col-lg-10 col-md-10">
            <div className="mission-statement mb-4">
              <h4 className="">Mission Statement</h4>
              <p>
                Our mission is to enhance cross-border connections through various industries—ensuring smooth integration of cultures, opportunities, and resources across Japan and Africa.
              </p>
            </div>
            <div className="vision-statement">
              <h4 className="">Vision</h4>
              <p>
                To be the leading force in cultural exchange and global trade, enriching lives through food, education, and connectivity.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="our-story-section">
   
      <div className="container py-5">
      <div className="text-center">
          <div class="intro-highlight-container mb-3 mt-5">
            <div class="intro-highlight-background"></div>
            <h2 class="intro-highlight-text mb-3">Our Story</h2>
          </div>
        </div>
        <div className="row">
          {/* Text Content */}
          <div className="col-lg-7">
            
            <div className="company-background mb-4">
              <h4 className="text-success">Company Background:</h4>
              <p>
                Founded by a team with extensive experience in international business and cultural exchange, Hands Across Borders has been bridging the gap between Japan and Africa since its inception. We started with a vision to introduce Africa's rich cultural heritage, food, and educational opportunities to Japan and vice versa.
              </p>
            </div>
            <div className="key-services">
              <h4 className="text-success">Key Services:</h4>
              <ul className="list-unstyled">
                <li>
                  <img src={check} alt="Checkmark" className="check-icon" />
                  Human Resources & Connectivity
                </li>
                <li>
                  <img src={check} alt="Checkmark" className="check-icon" />
                  Food Importation (African food to Japan)
                </li>
                <li>
                  <img src={check} alt="Checkmark" className="check-icon" />
                  Used Cars & Parts Trade
                </li>
                <li>
                  <img src={check} alt="Checkmark" className="check-icon" />
                  Tourism Services (between Japan and Africa)
                </li>
                <li>
                  <img src={check} alt="Checkmark" className="check-icon" />
                  Education Enrollment for students
                </li>
              </ul>
            </div>
          </div>
          {/* Image Content */}
          <div className="col-lg-5">
            <div className="side-image">
              <img src={our} alt="Handsome African Guy" className="img-fluid rounded" />
            </div>
          </div>
        </div>
      </div>
    </section>


    <section className="our-team">
      <div className="container">
      <div className="text-center">
          <div class="intro-highlight-container mb-3 mt-5">
            <div class="intro-highlight-background"></div>
            <h2 class="intro-highlight-text mb-3">Our Story</h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-12 col-md-10 text-center">
         
            
            <p className="lead">
              Hands Across Borders is a dynamic limited liability company with a global outlook, committed to bridging the gap between Japan and Africa. We specialize in fostering cultural exchange and providing essential services that include food importation, tourism, education, and more. Our aim is to create lasting connections that transcend borders, offering individuals and businesses the opportunity to experience the richness of different cultures.
            </p>
          </div>
        </div>

        <div className="container team-container">
      <div className="row justify-content-center team-card-row">
        {/* First Card */}
        <div className="col-md-6 col-lg-5">
          <div className="team-card">
            <div className="img-container">
              <img
                className="team-card-img"
                src={team1}
                alt="Team Member"
              />
            </div>
            <div className="overlay">
              <div className="text-content">
                <p className="name">Ebun Magdalene</p>
                <p className="position mb-3">Company CEO</p>
                <div className="social-icons">
                <p><a href="tel:+817044813346">Tel: +81 70 4481 3346</a></p>
               
              </div>
              </div>
            
            </div>
            <div className="bottom-bar">
              <div className="white-section"></div>
              <div className="green-border"></div>
            </div>
          </div>
        </div>

        {/* Second Card */}
        <div className="col-md-6 col-lg-5">
          <div className="team-card">
            <div className="img-container">
              <img
                className="team-card-img"
                src={team2}
                alt="Team Member"
              />
            </div>
            <div className="overlay">
              <div className="text-content">
                <p className="name">Etim Terence</p>
                <p className="position ">General Manager</p>
                <div className="social-icons">
                <p><a href="tel: +2377041942233">Tel: +81 70 4194 2233</a></p>
               
              </div>
              </div>
             
            </div>
            <div className="bottom-bar">
              <div className="white-section"></div>
              <div className="green-border"></div>
            </div>
          </div>
        </div>

        {/* Third Card */}
        <div className="col-md-6 col-lg-5">
          <div className="team-card">
            <div className="img-container">
              <img
                className="team-card-img"
                src={team3}
                alt="Team Member"
              />
            </div>
            <div className="overlay">
              <div className="text-content">
                <p className="name">Etim Emmanuel Asian</p>
                <p className="position">Overseas Manager</p>
                <div className="social-icons">
                <p><a href="tel: 237656222874 ">Tel: +237 656 222 874</a></p>
               
              </div>
              </div>
             
            </div>
            <div className="bottom-bar">
              <div className="white-section"></div>
              <div className="green-border"></div>
            </div>
          </div>
        </div>

        {/* Fourth Card */}
        <div className="col-md-6 col-lg-5">
          <div className="team-card">
            <div className="img-container">
              <img
                className="team-card-img"
                src={team4}
                alt="Team Member"
              />
            </div>
            <div className="overlay">
              <div className="text-content">
                <p className="name">Etim Elizabeth Ndarake</p>
                <p className="position ">Overseas Human Resources Manager</p>
                <div className="social-icons">
                <p><a href="tel: 2376727918862">Tel: +237 672 791 8862</a></p>
               
              </div>
              </div>
             
            </div>
            <div className="bottom-bar">
              <div className="white-section"></div>
              <div className="green-border"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
      </div>
    </section>
    {/*CONTACT US */}
   <Contactcom/>
  </main>
  )
}
