import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './Contact.css';
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaCaretDown,
} from "react-icons/fa";
import { motion } from 'framer-motion';

const Contactcom = () => {
  const form = useRef();
  const [contactMessage, setContactMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ihzfrae','template_kl96fxs' , form.current,'Kl_bohtXa0D9WQl-x')
      .then((result) => {
          console.log(result.text);
          setContactMessage('Message Sent Successfully ✅');

          setTimeout(() => {
            setContactMessage('');
          }, 5000);

          form.current.reset();
      }, (error) => {
          console.log(error.text);
          setContactMessage('Message Not Sent, Please try again later ❌');
      });
  };

  return (
    <motion.div 
      className="container contact-us-container mt-5 py-2"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
    >
      <motion.div
        class="highlight-container"
        initial={{ x: -200, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <div class="highlight-background"></div>
        <h2 class="highlight-text">Contact Us</h2>
      </motion.div>
      
      <div className="row">
        {/* Left side with contact details */}
        <motion.div 
          className="col-lg-4 col-md-12 mb-4 contact-info"
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <div className="contact-details mt-5">
            {/* Address */}
            <div className="d-flex align-items-start mb-4">
              <div className="icon-bg me-3">
                <FaMapMarkerAlt className="contact-icon" />
              </div>
              <div>
                <h5>Address</h5>
                <p>
                  329-0201 Awanomiya 1-4-25
                  <br /> Maison Soleil 201 Tochigi<br/> Prefecture
                  Oyama city
                  Japan.
                </p>
              </div>
            </div>
            {/* Call Us */}
            <div className="d-flex align-items-start mb-4">
              <div className="icon-bg me-3">
                <FaPhoneAlt className="contact-icon" />
              </div>
              <div>
                <h5>Call Us</h5>
                <p>+81 70 4194 2233<br/>
                Africa and Overseas Manager</p>
              </div>
            </div>
            {/* Email Us */}
            <div className="d-flex align-items-start mb-4">
              <div className="icon-bg me-3">
                <FaEnvelope className="contact-icon" />
              </div>
              <div>
                <h5>Email Us</h5>
                <p>handacrborders@gmail.com</p>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Right side with the form */}
        <motion.div 
          className="col-lg-8 col-md-12  mb-4"
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <form ref={form} onSubmit={sendEmail} className="mt-5">
            <div className="row mb-3">
              {/* full Name */}
              <div className="col-md-6 mb-3">
                <input
                  type="text"
                  name="full_name"
                  className="form-control"
                  placeholder="Full Name"
                  required
                />
              </div>
              {/* email */}
              <div className="col-md-6 mb-3">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                  required
                />
              </div>
            </div>

            {/* Reason for Contact with drop-down icon */}
            <div className="mb-3 position-relative">
              <select className="form-control" name="contact_reason" required>
                <option value="null">Reason For Contact</option>
                <option value="support">Human Resource Services</option>
                <option value="Food Importation">Food Importation</option>
                <option value="Arts & Crafts">Arts & Crafts</option>
                <option value="Education">Education</option>
                <option value="Music & Entertainment">Music & Entertainment</option>
                <option value="Tourism">Tourism</option>
                <option value="Used Cars">Used Cars, Parts & Items</option>
              </select>
              <FaCaretDown className="dropdown-icon" />
            </div>

            {/* Message */}
            <div className="mb-4">
              <textarea
                className="form-control"
                name="message"
                rows="5"
                placeholder="Message"
                required
              ></textarea>
            </div>

            <div className="row">
              <div className="col-12">
                <p className="contact-message">{contactMessage}</p>
              </div>
            </div>

            {/* Submit Button */}
            <motion.div
              className="text-center"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <button type="submit" className="btn">
                Send Message
              </button>
            </motion.div>
          </form>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Contactcom;
