import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Service.css'
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import service1 from '../../assets/images/service1.webp';
import service2 from '../../assets/images/service2.webp';
import service3 from '../../assets/images/service3.webp';
import service4 from '../../assets/images/service4.webp';
import service5 from '../../assets/images/service5.webp';
import service6 from '../../assets/images/service6.webp';
import service7 from '../../assets/images/service7.webp';
import { motion, AnimatePresence } from 'framer-motion';
import cheack from '../../assets/images/check.png';
import Contactcom from '../../Component/Contact/Contactcom';



const services = [
  {
    id: 1,
    name: "Human Resource Services",
    image: service1, 
    subtitle: "Empowering Global Workforce Connections",
    content: "At Hands Across Borders, we understand that the right talent is crucial for business success. Our Human Resource Services go beyond simple recruitment—we offer comprehensive solutions that connect businesses with top-tier talent worldwide. By focusing on cross-border recruitment, we ensure that companies can overcome geographical limitations and find the skilled professionals they need. Additionally, our cultural integration support helps ensure that new hires smoothly transition into diverse workplace environments, fostering harmony and productivity.",
    checks: [
      "Global talent recruitment",
      "Cross-Border Recruitment Solutions",
      "Cultural Integration Support"
    ],
    para1: "We take pride in not just finding talent, but in creating lasting connections between employers and employees. Our approach is centered on understanding the unique needs of each client and candidate, ensuring that every match leads to long-term success. By focusing on both the professional and cultural fit, we help build workplaces that are diverse, inclusive, and equipped to thrive in a global market.",
    para2: "Our services are designed to empower businesses and individuals to explore opportunities beyond their local markets. We believe that by facilitating these connections, we are contributing to a more dynamic and interconnected global economy. Whether you are a business looking to expand your talent pool or an individual seeking new career horizons, our Human Resource Services & Connectivity solutions are here to guide you every step of the way.",
  },
  {
    id: 2,
    name: "Food Importation",
    image: service2,
    subtitle: "Savor the Richness of African Cuisine",
    content: "Our food importation services are your gateway to the vibrant flavors of Africa. We take pride in importing culturally authentic food products that bring the rich culinary traditions of Africa to your doorstep in Japan. Each product is carefully selected for its quality and freshness, ensuring that every bite offers an authentic taste experience. Whether you are looking to explore new flavors or connect with the comfort of familiar dishes, our imported foods bring the best of Africa to your table.",
    checks: [
      "Specialty African Ingredients",
      "Quality Assurance & Freshness",
      "Culturally Authentic Products"
    ],
    para1: "Beyond bringing delicious food to new markets, we are committed to supporting the communities from which we source our products. By working directly with African farmers and suppliers, we promote fair trade practices and help ensure that the benefits of our business are shared across the supply chain. Our approach not only delivers exceptional products to consumers but also contributes to the sustainable development of local economies in Africa.",
    para2: "Food is more than just sustenance; it is a powerful connector of cultures and people. Through our importation services, we aim to build bridges between Africa and Japan, allowing people to experience the richness of African culture through its cuisine. Whether it's a taste of home for expatriates or a new culinary adventure for locals, our food importation services make these cultural exchanges possible, enriching lives on both sides of the globe.",
  
  },
  {
    id: 3,
    name: "Arts & Crafts",
    image: service3, 
    subtitle: "Fostering Artistic Expression Across Borders",
    content: "At Hands Across Borders, we are passionate about art’s ability to communicate and connect. Our Arts & Crafts services are dedicated to promoting the rich artistic heritage of both Africa and Japan. We organize cultural art exhibitions that allow these unique traditions to shine, offering workshops that give participants hands-on experience with traditional and modern crafts. By supporting both established and emerging artists, we help preserve and promote the artistic expressions that are integral to these cultures.",
    checks: [
      "Cultural Art Exhibitions",
      "Workshops & Artisanship",
      "Promotion of Traditional and Modern Art"
    ],
    para1: "Through our initiatives, we seek not only to preserve but also to innovate within the arts. We encourage cross-cultural collaborations that inspire new forms of artistic expression, blending the techniques and traditions of Africa and Japan. This exchange enriches the artistic communities in both regions, allowing artists to explore new ideas and reach new audiences. Our goal is to create a vibrant artistic ecosystem where creativity knows no boundaries.",
    para2: "Art has the power to transcend language and geographical barriers, bringing people together in ways that few other mediums can. Our Arts & Crafts services are designed to facilitate this connection, creating opportunities for communities to engage with art on a deeper level. Whether it's through attending an exhibition, participating in a workshop, or purchasing a piece of art, we believe that everyone can play a role in fostering cultural understanding and appreciation. By supporting and promoting the arts, we help to build a world where creativity and culture are celebrated and shared.",
  },
  {
    id: 4,
    name: "Education",
    image: service4, 
    subtitle: "Making Global Education Accessible",
    content: "We believe that education is the foundation of a brighter future. Our services are designed to connect international students with educational opportunities in Japan, from universities to language schools. We provide comprehensive support throughout the application process, including assistance with documentation and immigration procedures. Our goal is to ensure that students have everything they need to succeed academically and personally while studying abroad.",
    checks: [
      "Admissions to Japanese Institutions",
      "Student Support Services",
      "Cultural & Academic Integration"
    ],
    para1: "Our commitment to education extends beyond academic success. We aim to nurture students who are not only knowledgeable but also culturally aware and globally minded. By helping students integrate into Japanese society and navigate the challenges of studying abroad, we prepare them to become global citizens who can contribute positively to the world. Our support continues throughout their educational journey, ensuring they have the resources they need to thrive.",
    para2: "At Hands Across Borders, we believe that learning is a lifelong journey. Our education services are not just about getting students into schools; they are about providing the support and guidance needed to succeed at every stage. We offer resources and programs that help students adapt to their new environments, overcome challenges, and make the most of their educational experiences. Whether you are a student just starting your academic journey or someone looking to further your education, we are here to help you achieve your goals.",
  },
  {
    id: 5,
    name: "Music & Entertainment",
    image: service5, 
    subtitle: "Uniting Cultures Through Music and Performance",
    content: "Music and entertainment are powerful bridges between cultures, and our services at Hands Across Borders are designed to harness that power. We organize cultural music festivals that celebrate the rich traditions of Africa and Japan, creating spaces where artists from both regions can collaborate and perform. Our event planning expertise ensures that every performance is a memorable experience, resonating with audiences and promoting cross-cultural understanding. Whether it’s a concert, a dance performance, or a festival, we bring the joy of cultural exchange to life through music and entertainment.",
    checks: [
      "Cultural Music Festivals",
      "Artist Collaborations",
      "Event Planning & Management"
    ],
    para1: "We believe that music and entertainment are more than just performances—they are shared experiences that bring people together. Our events are designed to foster connections between audiences and artists, creating moments of joy, reflection, and understanding. By showcasing the diverse musical traditions of Africa and Japan, we aim to inspire a deeper appreciation of the cultural richness that these regions offer. Our work in music and entertainment is about building bridges, creating memories, and celebrating the diversity that makes our world unique.",
    para2: "At Hands Across Borders, we actively seek to create opportunities for artists from different backgrounds to collaborate and learn from each other. By bringing together musicians, performers, and cultural ambassadors, we foster an environment of creativity and mutual respect. These collaborations not only enrich the artists involved but also provide audiences with unique and innovative performances that highlight the best of both African and Japanese cultures. Our commitment to music and entertainment is driven by our belief in its power to connect, inspire, and transform.",
  },
  {
    id: 6,
    name: "Tourism",
    image: service7, 
    subtitle: "Experience Cultures Firsthand Through Our Tours",
    content: "Explore the world with Hands Across Borders, where our Tourism services offer more than just sightseeing—they offer immersive cultural experiences. Our guided tours of Japan and Africa are carefully designed to provide deep insights into the traditions and lifestyles of these regions. Participants can engage directly with local communities, learn about historical and cultural landmarks, and even experience daily life through our authentic homestay programs. Whether you’re an adventurer or a cultural enthusiast, our tourism services provide a unique, enriching experience.",
    checks: [
      "Cultural Exchange Programs",
      "Guided Tours of Japan & Africa",
      "Event Planning & Management"
    ],
    para1: "Travel is one of the most powerful ways to foster understanding between different cultures. Our tourism programs are not just about seeing new places—they are about experiencing the world through the eyes of the people who live there. By participating in our tours, travelers gain a deeper appreciation of the cultural nuances that define Japan and Africa. We create opportunities for meaningful interactions, ensuring that every journey is an educational and transformative experience that brings people closer together.",
    para2: "Our tours are designed to leave a lasting impact on travelers, providing experiences that are both memorable and educational. From the bustling markets of Africa to the serene temples of Japan, we curate each itinerary to ensure that participants gain a true understanding of the cultures they are exploring. Through our homestay programs, travelers have the unique opportunity to live with local families, experiencing everyday life in a new and profound way. At Hands Across Borders, we believe that travel is about more than just seeing the world—it’s about connecting with it.",
  },
  {
    id: 7,
    name: "Used Cars, Parts & Items",
    image: service6, 
    subtitle: "Reliable Products for Sustainable Living",
    content: "At Hands Across Borders, our Used Cars, Parts & Items services are all about providing high-quality, affordable options for our customers. Each vehicle and part we offer has been rigorously inspected to meet our standards of excellence, ensuring reliability and safety. By offering used goods, we also promote sustainable living, helping",
    checks: [
      "High-Quality Inspected Vehicles",
      "Affordable Auto Parts",
      "Sustainable & Cost-Effective Solutions"
    ],
    para1: "At Hands Across Borders, our Used Cars, Parts & Items services are all about providing high-quality, affordable options for our customers. Each vehicle and part we offer has been rigorously inspected to meet our standards of excellence, ensuring reliability and safety. By offering used goods, we also promote sustainable living, helping",
    para2: "By offering used goods, we also promote sustainable living, helping to reduce waste and minimize environmental impact. Our commitment to sustainability goes beyond just offering affordable options; it’s about making responsible choices that benefit both our customers and the planet. We believe that high-quality, pre-owned vehicles and parts can offer excellent value without compromising on safety or performance. Through our services, we aim to provide products that are not only budget-friendly but also contribute to a more sustainable future.",
  },
];

export const Service = () => {
  const [selectedService, setSelectedService] = useState(services[0]);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    const service = services.find(s => s.name.replace(/\s+/g, '-') === hash);
    if (service) {
      setSelectedService(service); // Update the selected service based on URL hash
      document.getElementById(hash)?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  const handleServiceChange = (service) => {
    if (isTransitioning) return;

    setIsTransitioning(true);
    setTimeout(() => {
      setSelectedService(service);
      setIsTransitioning(false);
    }, 500); // Transition time
  };

  return (
    <main>
      <div className="about-us-section">
        <div className="container text-center py-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10">
              <h2 className="mb-4">
                OUR <span>SERVICES</span>
              </h2>
              <p className="lead">Connecting Cultures, People, and Opportunities</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container services-container mt-5">
        <div className="row">
          <div className="service-gap"></div>
          <div className="col-md-5 service-list">
            <div className="list-group">
              {services.map(service => (
                <button
                  key={service.id}
                  id={service.name.replace(/\s+/g, '-')}
                  onClick={() => handleServiceChange(service)}
                  className={`list-group-item list-group-item-action d-flex align-items-center ${selectedService.id === service.id ? 'active' : ''}`}
                >
                  <FaRegArrowAltCircleRight className='arrowalt' />
                  {service.name}
                </button>
              ))}
            </div>
          </div>

          <div className="col-md-6 text-center">
            <AnimatePresence>
              {/* Animated Image */}
              <motion.img
                key={selectedService.image}
                src={selectedService.image}
                alt={selectedService.name}
                className="img-fluid service-image"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.5 }}
              />
            </AnimatePresence>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-12">
            {/* Animated Header and Content */}
            <AnimatePresence>
              <motion.h3
                key={selectedService.subtitle}
                className="service-header mt-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5 }}
              >
                {selectedService.subtitle}
              </motion.h3>

              <motion.p
                key={selectedService.content}
                className="service-content"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5 }}
              >
                {selectedService.content}
              </motion.p>

              <motion.ul
                key={selectedService.checks}
                className="list-unstyled"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5 }}
              >
                {selectedService.checks.map((check, index) => (
                  <li key={index} className="d-flex align-items-center serviceli">
                    <img src={cheack} alt="checkmark" className="check-icon" />
                    <span className="ml-2 check">{check}</span>
                  </li>
                ))}
              </motion.ul>

              <motion.p
                key={selectedService.para1}
                className="service-content mt-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5 }}
              >
                {selectedService.para1}
              </motion.p>

              <motion.p
                key={selectedService.para2}
                className="service-content mt-3"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5 }}
              >
                {selectedService.para2}
              </motion.p>
            </AnimatePresence>
          </div>
        </div>
      </div>

      <Contactcom />
    </main>
  );
};
