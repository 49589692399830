import React, { useState } from 'react';
import './Header.css';
import { NavLink, Link } from 'react-router-dom';
import logo from '../../assets/images/logo.svg'

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false); 
  };

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-dark  sticky-top custom-navbar">
        <div className="container-fluid">
          {/* Placeholder logo */}
          <Link className="navbar-brand d-flex align-items-center " to="/" onClick={closeMenu}>
            <img src={logo} alt="Logo" className="custom-logo" />
            <span className="brand-name">Hands Across Borders</span>
                      </Link>
          {/* Toggle button with Font Awesome icons?: could not use react icons */}
          <button className="navbar-toggler custom-toggler" type="button" onClick={toggleMenu}>
            <i className={`fas ${menuOpen ? 'fa-times' : 'fa-bars'}`}></i>
          </button>
          <div className={`navbar-collapse collapse ${menuOpen ? 'show' : ''}`} id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <NavLink 
                  className={({ isActive }) => `nav-link ${isActive ? 'active-link' : ''}`} 
                  to="/" 
                  onClick={closeMenu}
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink 
                  className={({ isActive }) => `nav-link ${isActive ? 'active-link' : ''}`} 
                  to="/about" 
                  onClick={closeMenu}
                >
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink 
                  className={({ isActive }) => `nav-link ${isActive ? 'active-link' : ''}`} 
                  to="/service" 
                  onClick={closeMenu}
                >
                  Services
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink 
                  className={({ isActive }) => `nav-link ${isActive ? 'active-link' : ''}`} 
                  to="/contact" 
                  onClick={closeMenu}
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
