import React from "react";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Header from "./Component/Header/Header";
import Footer from "./Component/Footer/Footer";
import ScrollToTop from './ScrollToTop';
import Home from "./Pages/Home/Home";
import { About } from "./Pages/About/About";
import { Service } from "./Pages/Service/Service";
import { Contact } from "./Pages/Contact/Contact";

const App = () => {

  return (
    <div>
      
        <Router>
           <div className="">
           <ScrollToTop />
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/service" element={<Service />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <Footer/>
          </div>
        </Router>
    
    </div>
  );
};

export default App;
