import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { FaInstagram, FaLinkedin } from "react-icons/fa6";
import logo from '../../assets/images/logo.svg';
import './Footer.css';

const Footer = () => {
  const [ref] = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <motion.footer
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true, amount: 0.1 }}
      className="footer"
    >
      <div className="container-fluid padding-container">
        <div className="row text-center text-md-start">
          <div className="col-md-4 mb-4 d-flex flex-column align-items-center">
            <img src={logo} alt="Logo" className="footer-logo mb-3" />
            <h5 className="mb-4">Hands Across Borders</h5>
            <div className="footer-social-icons">
              <Link to="https://www.instagram.com/handacrborders" className="social-icon"><FaInstagram className='icon'/></Link>
              <Link to="https://www.linkedin.com/in/handsacrossborders/" className="social-icon"><FaLinkedin className='icon'/></Link>
              
            </div>
          </div>

          <div className="col-md-4 mb-2 quick-links-services d-md-flex mt-2">
            <div className="col-8">
              <h6 className="footer-title">Quick Links</h6>
              <ul className="list-unstyled">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/service">Services</Link></li>
                <li><Link to="/contact">Contact Us</Link></li>
              </ul>
            </div>

            <div className="col-8">
              <h6 className="footer-title services">Services</h6>
              <ul className="list-unstyled">
                <li><Link to="/service#Human-Resource-Services">Human Resources</Link></li>
                <li><Link to="/service#Food-Importation">Food Importation</Link></li>
                <li><Link to="/service#Used-Cars,-Parts-&-Items">Used Cars & Parts</Link></li>
                <li><Link to="/service#Tourism">Tourism</Link></li>
                <li><Link to="/service#Education">Education</Link></li>
              </ul>
            </div>
          </div>

          <div className="col-md-4 mb-2 contact-us text-md-center mt-2">
            <h6 className="footer-title">Contact Us</h6>
            <p className="mb-3">Phone: +81 70-4194-2233<br/>General Managing Director</p>
            <p><a href="mailto:handacrborders@gmail.com?subject=Inquiry&body=Hello,%20I%20would%20like%20to%20know%20more%20about...">Email: handacrborders@gmail.com</a></p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <hr className="footer-separator" />
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center">
            <p className="text-muted mb-0">© Copyright handsacrossborders All Rights Reserved</p>
          </div>
        </div>
      </div>
    </motion.footer>
  );
};

export default Footer;
